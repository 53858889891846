<template>
  <div
    v-if="lightBox"
    :class="
      isLightbox &&
      'fixed left-0 top-0 z-50 flex !h-screen !w-screen items-center justify-center bg-[#f2f0e9] bg-opacity-90 px-4'
    "
  >
    <button v-if="isLightbox" class="absolute right-4 top-4 z-[100] lg:right-6 lg:top-6">
      <Icon name="close" @click="closeLightbox" />
    </button>
    <div class="flex flex-col">
      <img
        ref="imageRef"
        class="transform-gpu"
        :class="[
          {
            ' duration-400 transition-opacity ease-linear': effect === 'fade'
          },
          {
            'w-full max-w-[860px] lg:max-w-[960px] xl:max-w-[1280px]': isLightbox
          },
          effect === 'fade' && isVisible ? 'opacity-100' : 'opacity-0'
        ]"
        :src="src"
        :srcset="srcset"
        :sizes="sizes"
        :loading="loading && loading"
        :width="width"
        :height="height"
        :alt="alt"
        @load="onLoad($event)"
        @error="onError($event)"
        @click="openLightbox"
      />
      <div class="mt-6 pr-24 text-sm" :class="isLightbox ? 'text-black' : 'text-gray-70'">
        {{ title }}
      </div>
    </div>
  </div>
  <img
    v-else
    ref="imageRef"
    class="transform-gpu"
    :class="[
      {
        ' duration-400 transition-opacity ease-linear': effect === 'fade'
      },
      effect === 'fade' && isVisible ? 'opacity-100' : 'opacity-0'
    ]"
    :src="src"
    :srcset="srcset"
    :sizes="sizes"
    :loading="loading && loading"
    :width="width"
    :height="height"
    :alt="alt"
    @load="onLoad($event)"
    @error="onError($event)"
  />
</template>

<script setup lang="ts">
import { UseIntersectionObserverOptions, useIntersectionObserver } from '@vueuse/core'

export interface Image {
  src: string
  srcset?: string
  width: number
  height: number
  alt: string
  sizes?: string
  title?: string
  loading?: 'eager' | 'lazy'
  effect?: 'fade' | 'none'
  observerOptions?: UseIntersectionObserverOptions
  lightBox?: boolean
}

const props = withDefaults(defineProps<Image>(), {
  effect: 'fade',
  lightBox: false,
  observerOptions: () => ({
    threshold: 0.5
  })
})

const { bodyLock, bodyUnlock } = useBodyLock()
const imageRef = ref<HTMLElement | null>(null)
const isLoaded = ref(false)
const isVisible = ref(false)
const isLightbox = ref(false)

defineExpose({
  imageRef
})

const emit = defineEmits(['load'])

function onLoad(e: Event) {
  isLoaded.value = true
  emit('load', e)
}

function onError(e: Event) {
  console.error(e)
}

function openLightbox() {
  if (!isLightbox.value) {
    isLightbox.value = true
    bodyLock()
  }
}

function closeLightbox() {
  isLightbox.value = false
  bodyUnlock()
}

onMounted(() => {
  if (props.lightBox) {
    window.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        closeLightbox()
      }
    })
  }
})

const { stop } = useIntersectionObserver(
  imageRef,
  ([{ isIntersecting }], _observerElement) => {
    if (isIntersecting) {
      isVisible.value = true
    }
  },
  props.observerOptions
)

onUnmounted(() => {
  stop()

  if (props.lightBox) {
    window.removeEventListener('keydown', e => {
      if (e.key === 'Escape') {
        closeLightbox()
      }
    })
  }
})
</script>
